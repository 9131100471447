/* DARK MODE */

/*
header color: fafbf8
text color: b3b3b3
disabled text: 767676
background: 323232


header color: fefdfb
text color: 505050
bg: 1f1f1f
*/

html[data-theme="corvia dark"] {
  --color-primary-hue: 42;
  --color-primary-saturation: 55%;
  --color-primary-lightness: 44%;
  --color-primary: hsl(var(--color-primary-hue), var(--color-primary-saturation), var(--color-primary-lightness));
  --color-primary-complement: hsl(calc(var(--color-primary-hue) + 180), var(--color-primary-saturation), var(--color-primary-lightness));
  --color-primary-triadic1-hue: calc(var(--color-primary-hue) + 120);
  --color-primary-triadic1-saturation: calc(var(--color-primary-saturation));
  --color-primary-triadic1-lightness: calc(var(--color-primary-lightness) - 15%);
  --color-primary-triadic1: hsl(var(--color-primary-triadic1-hue), var(--color-primary-triadic1-saturation), var(--color-primary-triadic1-lightness));
  --color-primary-triadic1-complement: hsl(calc(var(--color-primary-triadic1-hue) + 180), var(--color-primary-triadic1-saturation), var(--color-primary-triadic1-lightness));
  --color-primary-triadic2-hue: calc(var(--color-primary-hue) + 240);
  --color-primary-triadic2-saturation: calc(var(--color-primary-saturation) - 30%);
  --color-primary-triadic2-lightness: calc(var(--color-primary-lightness) - 10%);
  --color-primary-triadic2: hsl(var(--color-primary-triadic2-hue), var(--color-primary-triadic2-saturation), var(--color-primary-triadic2-lightness));
  --color-primary-triadic2-complement: hsl(calc(var(--color-primary-triadic2-hue) + 180), var(--color-primary-triadic2-saturation), var(--color-primary-triadic2-lightness));
  --color-primary-tetrad1: hsl(calc(var(--color-primary-hue) + 90), var(--color-primary-saturation), var(--color-primary-lightness));
  --color-primary-tetrad2: hsl(calc(var(--color-primary-hue) - 90), var(--color-primary-saturation), var(--color-primary-lightness));
  --color-primary-split1: hsl(calc(var(--color-primary-hue) + 150), var(--color-primary-saturation), var(--color-primary-lightness));
  --color-primary-split2: hsl(calc(var(--color-primary-hue) - 150), var(--color-primary-saturation), var(--color-primary-lightness));
  --color-secondary-hue: 174;
  --color-secondary-saturation: 100%;
  --color-secondary-lightness: 20%;
  --color-secondary: hsl(var(--color-secondary-hue), var(--color-secondary-saturation), var(--color-secondary-lightness));
  --color-quinary-hue: 53;
  --color-quinary-saturation: 18%;
  --color-quinary-lightness: 91%;
  --color-quinary: hsl(var(--color-quinary-hue), var(--color-quinary-saturation), var(--color-quinary-lightness));
  --color-grey-hue: 25;
  --color-grey-saturation: 5%;
  --color-grey-lightness: 20%;
  --color-grey: hsl(var(--color-grey-hue), var(--color-grey-saturation), var(--color-grey-lightness));
  --color-dark-grey: hsl(var(--color-grey-hue), calc(var(--color-grey-saturation) - 70%), calc(var(--color-grey-lightness) - 50%));

  --color-disabled-hue: 210;
  --color-disabled-saturation: 2%;
  --color-disabled-lightness: 40%;
  --color-disabled: hsl(var(--color-disabled-hue), var(--color-disabled-saturation), var(--color-disabled-lightness));
          --color-text-hue: 0; /* 210 */
          --color-text-saturation: 0%; /* 17% */
          --color-text-lightness: 71%; /* 91% */
          --color-text: hsl(var(--color-text-hue), var(--color-text-saturation), var(--color-text-lightness));
  --color-heading-hue: var(--color-secondary-hue);
  --color-heading-saturation: var(--color-secondary-saturation);
  --color-heading-lightness: var(--color-secondary-lightness);
  --color-heading: hsl(var(--color-heading-hue), var(--color-heading-saturation), var(--color-heading-lightness));
          --color-whiteish-hue: 120;
          --color-whiteish-saturation: 11%;
          --color-whiteish-lightness: 15%;
          --color-whiteish: hsl(var(--color-whiteish-hue), var(--color-whiteish-saturation), var(--color-whiteish-lightness));
          --color-hr-hue: 120;
          --color-hr-saturation: 2%;
          --color-hr-lightness: 25%;
          --color-hr: hsl(var(--color-hr-hue), var(--color-hr-saturation), var(--color-hr-lightness));
          --color-data-border: #1f2321;
  --color-dusk-hue: 175;
  --color-dusk-saturation: 43%;
  --color-dusk-lightness: 10%;
  --color-dusk: hsl(var(--color-dusk-hue), var(--color-dusk-saturation), var(--color-dusk-lightness));
  --color-forest: var(--color-heading);
  --color-granite-green: #83a393;
  --color-sage: #c6dbcd;
  --color-mist: #e0e7e1;
  --color-deep-gold: var(--color-primary);
  --color-corvia-gold: #b49b56;
  --color-light-gold: var(--color-link);
  --color-stone: var(--color-grey);
  --color-air: var(--color-whiteish);
  --color-link: #bdad81;
  --color-link-hover: hsl(var(--color-primary-triadic1-hue), calc(var(--color-primary-triadic1-saturation) - 1%), calc(var(--color-primary-triadic1-lightness) + 6%));
  --color-white: #000000;
          --color-warning-hue: 0;
          --color-warning-saturation: 68%;
          --color-warning-lightness: 20%;
          --color-warning: hsl(var(--color-warning-hue), var(--color-warning-saturation), var(--color-warning-lightness));
          --color-warning-bg: #330b0b;

  --color-notice: #c59c4c;
  --color-success: #4c727e;
  --color-critical: #ea3323;
  --color-danger: #d35218;
  --color-risky: #dbbb6c;
  --color-healthy-hue: 174;
  --color-healthy-saturation: 100%;
  --color-healthy-lightness: 14%;
  --color-healthy: hsl(var(--color-healthy-hue), var(--color-healthy-saturation), var(--color-healthy-lightness));

        --color-healthy-bg: #0c2b27;

        --color-stoplight-red: #3f1015;
        --color-stoplight-red-hover: #511d22;
        --color-stoplight-orange: #864809;
        --color-stoplight-orange-hover: #91571c;
        --color-stoplight-yellow: #735d0d;
        --color-stoplight-yellow-hover: #7e6a1f;
        --color-stoplight-chartreuse: #615f07;
        --color-stoplight-chartreuse-hover: #74720a;
        --color-stoplight-green: #0e2b26;
        --color-stoplight-green-hover: #153631;

  --color-priority-very-low: #00B3F2;
  --color-priority-low: #4197b5;
  --color-priority-medium: #9c6ad4;
  --color-priority-high: #d5447e;
  --color-priority-very-high: #f40166;
  --color-priority-unprioritized: #c7c7c7;
  --color-priority-default: #e3e1e1;
  --color-status-open: #dfe1e6;
          --color-status-in-progress: #3d8aff;
  --color-status-completed: var(--color-sage);
  --color-status-will-not-complete: #FFAB3E;
  --color-status-duplicate: #e03e32;
  --color-status-closed: #b0b0b0;
  --font-family-heading: "Proza Libre", "Playfair Display", Georgia, Arial, sans-serif;
  --font-family-body:  "Graphik LCG", Verdana, Arial, sans-serif;
  --font-family-code: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  --radius-small: 4px;
  --radius-main: 8px;
  --radius-large: 24px;
          --color-page-background: #121512;
          --color-page-background-alt: var(--color-mist);
          --color-input-background: #292b2c;
          --color-tab-inactive: #1f2321;
          --color-row: #000000;
          --color-row-even: #070707;
          --color-filter-bar: #222727;
          --color-row-selected: #343645;
          --color-row-selected-hover: #d1d5ff;
          --color-row-hover: hsl(var(--color-primary-hue), var(--color-primary-saturation), calc(var(--color-primary-lightness) - 20%));
  --color-header: var(--color-corvia-gold);
  --color-header-bg: var(--color-dusk);
  --padding-header: 16px;
  --height-header: calc(50px + var(--padding-header) + var(--padding-header));
  --height-footer: 32px;
  --padding-databox: 20px;
  --color-link-alternate: var(--color-forest);
  --color-button-alternate: var(--color-air);
  --color-button-bg-alternate: var(--color-forest);
  --color-databox: var(--color-forest);
        --color-databox-bg: #171817;

        --color-databox-bg-alternate: #000000;
  --color-label: var(--color-forest);
  --color-light-label: hsl(var(--color-grey-hue), var(--color-grey-saturation), 40%);
  --color-tabbedContent-border: var(--color-deep-gold);
  --color-tooltip: var(--color-deep-gold);
  --color-form-subheader-bg: var(--color-sage);
  --color-form-subheader-option-bg: hsl(calc(var(--color-secondary-hue) + 25), calc(var(--color-secondary-saturation) - 27%), calc(var(--color-secondary-lightness) + 73%));

  --color-loader-overlay: rgba(0,0, 0, 0.8);

  --color-sidebar-bg: hsl(var(--color-quinary-hue), calc(var(--color-quinary-saturation) - 25%), calc(var(--color-quinary-lightness) - 85%));

  --sidebar-shadow: rgba(0, 0, 0, 1) 0 0 30px 0;

  --color-task-bg: #080f16;
  --color-task-border: #1a2735;
  --color-task-header: #1a2735;

  --color-task-approved-bg: #0a0f0b;
  --color-task-approved-border: var(--color-bg);
  --color-task-approved-header:#111c15;

  --color-task-pended-bg: #1a1605;
  --color-task-pended-border: hsl(var(--color-primary-hue), var(--color-primary-saturation), calc(var(--color-primary-lightness) + 20%));
  --color-task-pended-header: #3c2e10;

  --color-task-rejected-bg: #1f0507;
  --color-task-rejected-border: #d83636;
  --color-task-rejected-header: var(--color-warning);
  

  --color-menu-hover: #343331;
  --color-note-header: #292900;
}