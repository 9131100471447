/* Body Font*/
@font-face {
  font-family: 'Graphik LCG';
  src: url('../fonts/GraphikLCG-Regular.woff2') format('woff2'),
       url('../fonts/GraphikLCG-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Graphik LCG';
  src: url('../fonts/GraphikLCG-RegularItalic.woff2') format('woff2'),
       url('../fonts/GraphikLCG-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik LCG';
  src: url('../fonts/GraphikLCG-Bold.woff2') format('woff2'),
       url('../fonts/GraphikLCG-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Graphik LCG';
  src: url('../fonts/GraphikLCG-BoldItalic.woff2') format('woff2'),
       url('../fonts/GraphikLCG-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}
/* END Set Webfonts */

/* Set global variables */
html {
  /* DEEP-GOLD */
  /* --color-primary-hex: #a88a42; */
  --color-primary-hue: 42;
  --color-primary-saturation: 55%;
  --color-primary-lightness: 44%;
  --color-primary: hsl(var(--color-primary-hue), var(--color-primary-saturation), var(--color-primary-lightness));
  /* generate the complementary color for the primary color */
  --color-primary-complement: hsl(calc(var(--color-primary-hue) + 180), var(--color-primary-saturation), var(--color-primary-lightness));
  /* generate the first triadic color for the primary color */
  --color-primary-triadic1-hue: calc(var(--color-primary-hue) + 120);
  /* just tweaking saturation and lightness for variety */
  --color-primary-triadic1-saturation: calc(var(--color-primary-saturation));
  --color-primary-triadic1-lightness: calc(var(--color-primary-lightness) - 15%);
  --color-primary-triadic1: hsl(var(--color-primary-triadic1-hue), var(--color-primary-triadic1-saturation), var(--color-primary-triadic1-lightness));
  --color-primary-triadic1-complement: hsl(calc(var(--color-primary-triadic1-hue) + 180), var(--color-primary-triadic1-saturation), var(--color-primary-triadic1-lightness));
  /* generate the second triadic color for the primary color */
  --color-primary-triadic2-hue: calc(var(--color-primary-hue) + 240);
  --color-primary-triadic2-saturation: calc(var(--color-primary-saturation) - 30%);
  --color-primary-triadic2-lightness: calc(var(--color-primary-lightness) - 10%);
  --color-primary-triadic2: hsl(var(--color-primary-triadic2-hue), var(--color-primary-triadic2-saturation), var(--color-primary-triadic2-lightness));
  --color-primary-triadic2-complement: hsl(calc(var(--color-primary-triadic2-hue) + 180), var(--color-primary-triadic2-saturation), var(--color-primary-triadic2-lightness));
  /* generate the tetradic colors for the primary color */
  --color-primary-tetrad1: hsl(calc(var(--color-primary-hue) + 90), var(--color-primary-saturation), var(--color-primary-lightness));
  --color-primary-tetrad2: hsl(calc(var(--color-primary-hue) - 90), var(--color-primary-saturation), var(--color-primary-lightness));
  /* generate the split complementary colors for the primary color */
  --color-primary-split1: hsl(calc(var(--color-primary-hue) + 150), var(--color-primary-saturation), var(--color-primary-lightness));
  --color-primary-split2: hsl(calc(var(--color-primary-hue) - 150), var(--color-primary-saturation), var(--color-primary-lightness));

  /* FOREST */
  /* --color-secondary-hex: #004942; */
  --color-secondary-hue: 174;
  --color-secondary-saturation: 100%;
  --color-secondary-lightness: 14%;
  --color-secondary: hsl(var(--color-secondary-hue), var(--color-secondary-saturation), var(--color-secondary-lightness));

  /* --color-quinary: #edece5; */
  --color-quinary-hue: 53;
  --color-quinary-saturation: 18%;
  --color-quinary-lightness: 91%;
  --color-quinary: hsl(var(--color-quinary-hue), var(--color-quinary-saturation), var(--color-quinary-lightness));
  
  /* STONE */
  /* --color-grey: #dedbd2; */
  --color-grey-hue: 45;
  --color-grey-saturation: 15%;
  --color-grey-lightness: 85%;
  --color-grey: hsl(var(--color-grey-hue), var(--color-grey-saturation), var(--color-grey-lightness));

  --color-dark-grey: hsl(var(--color-grey-hue), calc(var(--color-grey-saturation) - 70%), calc(var(--color-grey-lightness) - 50%));

  /* --color-disabled: #cacaca; */
  --color-disabled-hue: 210;
  --color-disabled-saturation: 2%;
  --color-disabled-lightness: 84%;
  --color-disabled: hsl(var(--color-disabled-hue), var(--color-disabled-saturation), var(--color-disabled-lightness));

  /* DUSK */
  /* --color-text: #0f2624; */
  --color-text-hue: 175;
  --color-text-saturation: 43%;
  --color-text-lightness: 10%;
  --color-text: hsl(var(--color-text-hue), var(--color-text-saturation), var(--color-text-lightness));
  
  /* FOREST */
  /* --color-heading: #004942; */
  --color-heading-hue: var(--color-secondary-hue);
  --color-heading-saturation: var(--color-secondary-saturation);
  --color-heading-lightness: var(--color-secondary-lightness);
  --color-heading: hsl(var(--color-heading-hue), var(--color-heading-saturation), var(--color-heading-lightness));

   /* AIR */
  /* --color-whiteish: #f5f7f5; */
  --color-whiteish-hue: 120;
  --color-whiteish-saturation: 11%;
  --color-whiteish-lightness: 96%;
  --color-whiteish: hsl(var(--color-whiteish-hue), var(--color-whiteish-saturation), var(--color-whiteish-lightness));

  /* --color-brownish: #51463d */
  --color-brownish-hue: 25;
  --color-brownish-saturation: 14%;
  --color-brownish-lightness: 28%;
  --color-brownish: hsl(var(--color-whiteish-hue), var(--color-whiteish-saturation), var(--color-whiteish-lightness));
  
   /* --color-hr: #d5d6d7; */
   --color-hr-hue: 210;
   --color-hr-saturation: 2%;
   --color-hr-lightness: 90%;
   --color-hr: hsl(var(--color-hr-hue), var(--color-hr-saturation), var(--color-hr-lightness));

   
   --color-input-background: #ffffff;
 
   --color-warning-bg: #fae0e3;
   --color-healthy-bg: #f0fffd;

   --color-tab-inactive: #e2ede6;
   --color-data-border: #efefef;
   --color-row: #ffffff;
   --color-row-even: #f8f8f8;
   --color-edit-border: #87dcbb;
   --color-filter-bar: #EBEBEB;
   --color-advanced-filter-bar: var(--color-grey);
   --color-row-selected: #b3baff;
   --color-row-selected-hover: #d1d5ff;
   --color-row-hover: hsl(var(--color-primary-hue), var(--color-primary-saturation), calc(var(--color-primary-lightness) + 45%));
 
  /* We don't color shift the below colors, no need for hsl() */
  --color-forest: var(--color-heading);
  --color-granite-green: #83a393;
  --color-sage: #c6dbcd;
  --color-dusk: var(--color-text);
  --color-deep-gold: var(--color-primary);
  --color-corvia-gold: #b49b56;
  --color-light-gold: var(--color-link);
  --color-stone: var(--color-grey);
  --color-air: var(--color-whiteish);

  --color-link: #bdad81; /* LIGHT GOLD */
  --color-link-hover: hsl(var(--color-primary-triadic1-hue), calc(var(--color-primary-triadic1-saturation) - 1%), calc(var(--color-primary-triadic1-lightness) + 6%));
  --color-white: #ffffff;

  /* --color-warning: #921826; */
  --color-warning-hue: 353;
  --color-warning-saturation: 72%;
  --color-warning-lightness: 33%;
  --color-warning: hsl(var(--color-warning-hue), var(--color-warning-saturation), var(--color-warning-lightness));
  --color-notice: #c59c4c; /* updated for corvia */
  --color-notice-bg: #f6eddd; /* updated for corvia */
  --color-success: #4c727e; /* updated for corvia */
  --color-success-bg: #ddeff4; /* updated for corvia */

  --color-critical: #ea3323; /* updated for corvia */
  --color-danger: #d35218; /* updated for corvia */
  --color-risky: #dbbb6c; /* updated for corvia */

  /* --color-healthy: #004942; */
  --color-healthy-hue: 174;
  --color-healthy-saturation: 100%;
  --color-healthy-lightness: 14%;
  --color-healthy: hsl(var(--color-healthy-hue), var(--color-healthy-saturation), var(--color-healthy-lightness));

  /* d3 chart lines */
  --color-d3-chart-line: #ff7200;

  --color-stoplight-red: #921826; /* updated for corvia */
  --color-stoplight-red-hover: #ac2a39;
  --color-stoplight-orange: #FF8F1C;
  --color-stoplight-orange-hover: #fda043;
  --color-stoplight-yellow: #FFC600;
  --color-stoplight-yellow-hover: #fed540;
  --color-stoplight-chartreuse: #D2CF05;
  --color-stoplight-chartreuse-hover: #e7e424;
  --color-stoplight-green: #006a45;
  --color-stoplight-green-hover: #088257;

  --color-priority-very-low: #00B3F2;
  --color-priority-low: #4197b5;
  --color-priority-medium: #9c6ad4;
  --color-priority-high: #d5447e;
  --color-priority-very-high: #f40166;
  --color-priority-unprioritized: #c7c7c7;
  --color-priority-default: #e3e1e1;
  --color-status-open: #dfe1e6;
  --color-status-in-progress: #2e64b6;
  --color-status-completed: var(--color-sage);
  --color-status-will-not-complete: #FFAB3E;
  --color-status-duplicate: #e03e32;
  --color-status-closed: #b0b0b0;
  --color-status-waiting-on-partner: #474dfd;
  --color-status-waiting-on-bank: #ff7b00;
  --font-family-heading: "Proza Libre", "Playfair Display", Georgia, Arial, sans-serif;
  --font-family-body:  "Graphik LCG", Verdana, Arial, sans-serif;
  --font-family-code: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  --radius-small: 4px;
  --radius-main: 8px;
  --radius-medium: 14px;
  --radius-large: 24px;

  --transition-base: all 0.2s ease;

  --color-bg: var(--color-white);
  --color-bg-alt: var(--color-whiteish);
  --color-header: var(--color-corvia-gold);
  --color-header-bg: var(--color-dusk);
  --padding-header: 16px;
  --height-header: calc(50px + var(--padding-header) + var(--padding-header));
  --height-footer: 32px;
  --padding-databox: 20px;

  --color-link-alternate: var(--color-forest);
  --color-button-alternate: var(--color-air);
  --color-button-bg-alternate: var(--color-forest);
  --color-databox: var(--color-forest);
  --color-databox-bg: #f5f5f5;
  --color-databox-bg-alternate: var(--color-bg);

  --color-sidebar-bg: hsl(var(--color-quinary-hue), var(--color-quinary-saturation), calc(var(--color-quinary-lightness) + 5%));
  
  --color-label: var(--color-forest);
  --color-light-label: hsl(var(--color-grey-hue), var(--color-grey-saturation), 40%);
  --color-tabbedContent-border: var(--color-deep-gold);
  --color-tooltip: var(--color-deep-gold);
  --color-form-subheader-bg: var(--color-sage);
  --color-form-subheader-option-bg: hsl(calc(var(--color-secondary-hue) + 25), calc(var(--color-secondary-saturation) - 27%), calc(var(--color-secondary-lightness) + 73%));

  --color-loader-overlay: rgba(255, 255, 255, 0.8);
  --sidebar-shadow: rgba(0, 0, 0, 0.5) 0 0 30px 0;

  --color-task-bg: #f1f5fa;
  --color-task-border: #666a70;
  --color-task-header: #666a70;

  --color-task-approved-bg: #f0f5f1;
  --color-task-approved-border: var(--color-bg);
  --color-task-approved-header:#d3e4d9;

  --color-task-pended-bg: #fcfaf0;
  --color-task-pended-border: hsl(var(--color-primary-hue), var(--color-primary-saturation), calc(var(--color-primary-lightness) + 20%));
  --color-task-pended-header: hsl(var(--color-primary-hue), var(--color-primary-saturation), calc(var(--color-primary-lightness) + 46%));

  --color-task-rejected-bg: #fdf2f3;
  --color-task-rejected-border: hsl(var(--color-warning-hue), var(--color-warning-saturation), calc(var(--color-warning-lightness) + 35%));
  --color-task-rejected-header: var(--color-warning);

  --color-task-will-not-complete-bg: hsl(var(--color-brownish-hue), var(--color-brownish-saturation), calc(var(--color-brownish-lightness) + 67%));
  --color-task-will-not-complete-border: hsl(var(--color-brownish-hue), var(--color-brownish-saturation), calc(var(--color-brownish-lightness) + 72%));
  --color-task-will-not-complete-header:hsl(var(--color-brownish-hue), var(--color-brownish-saturation), calc(var(--color-brownish-lightness) + 50%));
  --color-task-will-not-complete-text:hsl(var(--color-brownish-hue), var(--color-brownish-saturation), calc(var(--color-brownish-lightness)));

  --color-menu-hover: #fbf7ee;
  --color-note-header: #ffffed;
}
/* END Set global variables */

* {
  box-sizing: border-box;
}
html {
  font-size: 10px;
}
body {
  margin: 0;
  padding: 0;
  font-size: 1.6rem;
  line-height: 2em;
  font-family: var(--font-family-body);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 320px;
  max-width: 100vw;
  color: var(--color-text);
  background-color: var(--color-bg);
  overscroll-behavior: none; /* remove bounce effect when over-scrolling page */
}
#root {
  /* main site wrapper, styles used for sticky footer */
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
strong {
  font-weight: 600;
}
fieldset {
  padding: 5px;
  margin-bottom: 10px;
  max-width: calc(100vw - 20px);
}
legend {
  width: 100%;
  background: black;
  color: var(--color-bg);
  padding: 0 5px;
  font-weight: bold;
}
code, .code {
  font-family: var(--font-family-code);
}
.codeBox {
  -moz-border-radius: var(--radius-main);
  -webkit-border-radius: var(--radius-main);
  border-radius: var(--radius-main);
}
.codeBox pre {
  margin: 0;
  padding: 10px;
  font-size: 1.2rem;
  line-height: 1.75;
  border-radius: var(--radius-main);
}
.code {
  color: var(--color-data-border);
  white-space: pre-wrap;
  background-color: #1d2021;
  border: 1px solid #dce4e5;
  padding: 10px;
  -moz-border-radius: 8px 8px 8px 8px;
  -webkit-border-radius: 8px 8px 8px 8px;
  border-radius: 8px 8px 8px 8px;
}
.code .key {
  margin-left: 20px;
}
.code .indent1 {
  margin-left: 20px;
}
.code .indent2 {
  margin-left: 40px;
}
.code .string {
  margin-left: 10px;
  color: #b7bb00;
}
.code .number {
  margin-left: 10px;
  color: #cd869b;
}
.code .bool {
  margin-left: 10px;
  color: #cd869b;
}
.clearfix:after {
  content: "";
  display: table;
  clear: both;
}
section {
  padding: 40px 0 ;
  clear: both;
}
h1, h2, h3, h4, h5 {
  font-family: var(--font-family-heading);
  font-weight: 300;
  color: var(--color-heading);
  line-height: 1.2;
}
h1 strong, h2 strong, h3 strong, h4 strong, h5 strong{
  font-weight: 400;
}
h1 {
  font-size: 4rem;
}
h2 {
  font-size: 2.6rem;
}
h3 {
  font-size: 2.2rem;
}
h4 {
  font-size: 2rem;
}
h5 {
  font-size: 1.6rem;
}
a {
  color: var(--color-link);
  text-decoration: none;
}
a:hover {
  color: hsl(var(--color-primary-triadic1-hue), calc(var(--color-primary-triadic1-saturation) - 1%), calc(var(--color-primary-triadic1-lightness) + 6%));
}

ol[type="1"] {
  list-style: none;
  padding: 0;
  margin: 0;
  counter-reset: item
}
ol[type="1"] ol[type="1"] > li {
  padding-left: 80px;
}
ol[type="1"] ol[type="1"] > li::before {
  width: 54px;
}
ol[type="1"] li ol[type="1"] {
  margin-left: -30px;
}
ol[type="1"] > li {
  display: block;
  position: relative;
  padding: 1em 0 1em 60px;
  counter-increment: listCounter;
}
ol[type="1"] > li::before {
  content: counters(item, ".") " ";
  counter-increment: item;
  color: #4d8457;
  font-size: 2rem;
  font-weight: bold;
  position: absolute;
  left: 0;
  line-height: 32px;
  width: 32px;
  height: 32px;
  top: .8em;
  border-right: 2px solid #acc5b0;
}

hr {
  border: none;
  border-bottom: 1px solid var(--color-hr);
  margin: 2em 0;
}

.tiny {
  font-size: 10px;
  text-transform: uppercase;
}

#signinForm .phoneField,
#signinForm #phone,
#signin .phoneRequired,
#security .codeResentMessage {
  animation: yellowfade 7s;
}

#main.reports { /* needed for datatable scroll bars */
  display: flex;
}

/* Table Styles */
table {
  border-collapse: collapse;
  height: 100%;
  line-height: 2;
}
td {
  padding: 0 10px;
  height: 100%;
}
.dataTable td, .dataTable th {
  transition: background-color 0.5s ease;
}
.dataTable td.highlight, .dataTable th.highlight {
  transition: background-color 0.5s ease;
  background: #fffcd7 !important;
}
tr {
  height: 100%;
}
.table-scroll {
  border-right: 1px solid var(--color-data-border);
  border-left: 1px solid var(--color-data-border);
  border-bottom: 1px solid var(--color-data-border);
  position: relative;
  padding: 3px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow-x: scroll;
  background: var(--color-row);
}
.table-wrap {
  position: relative;
}

.capFirst::first-letter {
  font-weight: bold;
  font-size: 2em;
}

tr:nth-child(even) {
  background: var(--color-row-even);
}
tr:nth-child(even) .table-invert-color {
  background-color: var(--color-row);
}

tr:nth-child(odd) .table-invert-color {
  background-color: var(--color-row-even);
}

table:not(.excel-table) td {
  border-right: 1px solid var(--color-data-border);
  position: relative;
}
table:not(.excel-table) td:last-child {
  border-right: none;
  position: relative;
}
td:last-child {
  border-right: none;
}
.excel-table td {
  border-right: unset;
}
.excel-table tbody tr:last-child {
  border-bottom: 1px solid black;
}
.edit td {
  border: 1px solid var(--color-edit-border);
  cursor: pointer;
}
.edit td:hover {
  border: 1px solid var(--color-edit-border);
  background: #ebffe5;
}
td.locked, td.locked:hover {
  padding: 0 10px;
  cursor: default !important;
  background: transparent !important;
}
.cell {
  width: 100%;
  height: 100%;
  min-height: 22px;
  padding: 0 10px;
  white-space: nowrap;
  outline: none;
  position: relative;
}
.hidden {
  display: none !important;
}
.maximized {
  /* must have a max height set somewhere */
  transition: var(--transition-base);
  opacity: 1;
}
.minimized {
  color: grey;
  transition: var(--transition-base);
  opacity: 0;
  max-height: 0 !important;
  min-height: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  overflow: hidden !important;
}
.noTransition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
.tabbedContent form.horizontal {
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
}

nav#siteNav.header.show {
  display: block !important;
}
#siteNav .navUl {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}

@media only screen and (min-width: 761px) {
  #siteNav .navUl > #reports #reportBar {
    left: 50% !important;
  transform: translateX(-50%);
  }
}

#vision p {
  padding: 40px;
  padding-top: 80px;
}

#whyChooseF1 li {
  min-width: calc(300px - 2%);
}

#nextSteps .closingNote {
  flex: 100%;
}

.summary {
  padding: 20px;
  margin: 10px;
}

.connectorStroke {
  stroke-width: 1.3;
}

.divider.top .angle {
  height: 40px;
}

#leaderChart .leaderTable .tableBody .dataTr:last-child .dataTd {
  border-bottom: 0 !important;
}

#leaderChart .leaderTable .tableBody .dataTr:first-child .dataTd:nth-child(odd) {
  border-top-left-radius: 10px !important;
}

#leaderChart .leaderTable .tableBody .dataTr:first-child .dataTd:nth-child(even) {
  border-top-right-radius: 10px !important;
}

#leaderChart .leaderTable .tableBody .dataTr:last-child .dataTd:nth-child(odd) {
  border-bottom-left-radius: 10px !important;
}

#leaderChart .leaderTable .tableBody .dataTr:last-child .dataTd:nth-child(even) {
  border-bottom-right-radius: 10px !important;
}

#sidebarContent form .formSection > div {
  margin-left: -1px !important;
}

#sidebarContent form .formSection > div:not(.sectionHeader) {
  margin-bottom: -1px !important;
}

/* MasonryAnimations */
.xmasonry .xblock {
  animation: comeIn ease 0.5s;
  animation-iteration-count: 1;
  transition: left .3s ease, top .3s ease;
}
.xmasonry .xblock > * {
  margin: 5px;
}
/* Media queries specifically for executives section */
/* ========= */
@media only screen and (max-width: 1510px) and (min-width: 1286px) {
  #executives {
    margin-right: 15%;
    margin-left: 15%;
  }
}

@media only screen and (max-width: 1285px) and (min-width: 1195px) {
  #executives {
    margin-right: 10%;
    margin-left: 10%;
  }
}
/* ========= */

@media only screen and (max-width: 1085px) {
  #sidebarContent form:not(.boardingForm) .formSection > div:not(.sectionHeader):not(.customSection):not(#currentAssignedGroups):not(#employeeGroupIds) {
    flex: 50% !important;
  }
  #sidebarContent form .formSection > div.dropMenu {
    flex: 100% !important;
  }
  #sidebarContent #relationshipForm form .formSection > div {
    flex: 100% !important;
  }
}

@media only screen and (max-width: 700px) {
  #sidebarContent form .formSection > div:not(.sectionHeader) {
    flex: 100% !important;
  }
}

@media only screen and (max-width: 1280px) {
  #siteNav.header > ul > li {
    font-size: 1.2rem;
  }
}

.mLink {
  height: 100px
}

@media only screen and (max-width: 460px) {
  #captcha div {
    width: 100% !important;
  }
}

@media only screen and (max-width: 685px) {
  #downloadButton {
    text-indent: -9999px;
    background-size: 60% !important;
  }
}

@media only screen and (max-width: 744px) {
  #downloadButton {
    text-indent: -9999px;
    background-size: 60% !important;
  }
}

@media only screen and (max-width: 695px) {
  .dataTable .paginationWrapper {
    flex-direction: column;
    align-items: baseline !important;
  }
}

@media only screen and (max-width: 435px) {
  .pagination .perPageWrapper {
    align-items: flex-start !important;
    flex-direction: column !important;
  }
}

@media only screen and (max-width: 420px) {
  #siteModal.active .modalHeader, #modal.active .modalHeader {
    padding-top: 30px !important;
  }
  #siteModal.active .modalContent #tableWrapper, #modal.active .modalContent #tableWrapper {
    height: auto;
  }
}

/* Pagination/download for reports */
@media only screen and (max-width: 700px) {
  #report .paginationShowing {
    display: none;
  }
}

@media only screen and (max-width: 905px) {
  #downloadButton {
    text-indent: -9999px;
    background-size: 60% !important;
    .btnIcon {
      left: 10px !important;
    }
  }
  #customizedReports #applicationStatusRollup.dataBox {
    padding-top: 5px !important;
  }
}

@media only screen and (max-width: 500px) {
  #report .tableFooter #label_per_page {
    display: none !important;
  }
  #fees #label_per_page {
    display: block !important;
  }
}

/* Pagination/download for fees */
@media only screen and (max-width: 920px) {
  #fees .paginationShowing {
    display: none;
  }
  #fees #downloadButton {
    text-indent: -9999px;
    background-size: 60% !important;
  }
}

@media only screen and (max-width: 709px) {
  #fees #label_per_page {
    display: none !important;
  }
}

@media only screen and (max-width: 655px) {
  #fees .tableFooter {
    flex-wrap: wrap;
  }
  #fees .perPageDisplay {
    margin-right: 0 !important;
  }
  #fees #label_per_page {
    display: block !important;
  }
  #fees .pagination {
    flex: 0 0 100%;
    max-width: initial !important;
  }
  #fees .dropMenu {
    flex: 0 0 50%;
    max-width: initial !important;
  }
}

/* START - siteNav styles */
@media only screen and (max-width: 1190px) {
  #siteNav.header.navLinks7 .navUl {
    flex-wrap: nowrap !important;
    width: 80% !important;
  }
}

@media only screen and (max-width: 1542px) {
  #siteNav.header.navLinks9 .navUl {
    flex-wrap: nowrap !important;
    width: 80% !important;
  }
}

@media only screen and (max-width: 1165px) {
  #siteNav.header.navLinks9 .navUl {
    width: calc(100% - 40px) !important;
  }
}

@media only screen and (max-width: 1198px) {
  #siteNav.header.navLinks6 .navUl {
    flex-wrap: nowrap !important;
    width: 80% !important;
  }
}

@media only screen and (max-width: 976px) {
  #siteNav.header.navLinks8 .navUl,
  #siteNav.header.navLinks7 .navUl,
  #siteNav.header.navLinks6 .navUl {
    flex-wrap: nowrap !important;
    width: calc(100% - 40px) !important;
  }
  footer .linksWrapper {
    flex-direction: column;
  }
}

@media only screen and (max-width: 530px) {
  footer .section {
    width: 50%;
  }
}

@media only screen and (max-width: 760px) {
  #siteNav .navUl > #reports #reportBar {
    left: -40px !important;
    transform: unset !important;
  }
}

#siteNav.mobile.header {
  display: none;
}
#siteNav.mobile ~ .Hamburger {
  display: block;
}
#siteNav.mobile.header.show .navUl {
  background: white;
  transition: all 0.2s ease 0s;
  border-radius: 6px 6px 6px 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 2px 2px 5px 0px;
  margin-top: 44px;
  width: 150px !important;
  float: right !important;
  height: auto !important;
  max-height: calc(100vh - 44px);
  overflow: auto;
  justify-content: flex-start !important;
  flex-wrap: wrap !important;
}
#siteNav.mobile.show {
  float: right;
}
#siteNav.mobile .submenuArrow {
  display: none !important;
}
#siteNav.mobile.header .navUl .navLi {
  max-width: unset !important;
  width: inherit !important;
  height: 40px !important;
  position: relative;
  margin: 0 !important;
  background: none !important;
  text-align: left !important;
}
#siteNav.mobile.header .navUl .navLi.parent {
  padding-left: 10px !important;
  height: 35px !important;
}
#siteNav.mobile.header .navUl .navLi.child {
  padding-left: 20px !important;
  height: 30px !important;
}
#siteNav.mobile.header .navUl > .navLi.parent:not(:first-child) {
  border-top: 1px solid var(--color-data-border) !important;
}
#siteNav.mobile.header .navUl .navLi .highlight {
  left: -10px;
}
#siteNav.mobile.header .navUl .navLi.child .highlight{
  top: 30px !important;
}
#siteNav.mobile.header .navUl > li > #accountLink span {
  display: block !important;
}
#siteNav.mobile.header .navUl #reportBar {
  display: none;
}
/* END - siteNav styles */

@media only screen and (max-width: 775px) { /* mobile */
  .pagination .perPageWrapper {
    align-items: center !important;
    flex-direction: row !important;
  }
  #page #main {
    width: 100vw !important;
  }
  #reportWrap .full,
  .tabbedSideContent .full,
  .TabbedContent .full {
    display: none !important;
  }
  #reportWrap .mobile,
  .tabbedSideContent .mobile,
  .TabbedContent .mobile {
    text-align: center;
    display: block !important;
  }
  .reportMiniBox {
    height: 80px !important;
    padding: 5px !important;
    flex-direction: column !important;
  }
  .reportMiniBox .value,
  .reportMiniBox .valueChange {
    text-align: center !important;
    margin-left: unset !important;
    height: auto !important;
    font-size: calc(1rem + 1vmin) !important;
    margin-top: 1px !important;
  }
  .reportMiniBox .range {
    font-size: calc(0.7rem + 1vmin) !important;
  }
  #sidebar, .sidebarNav {
    max-width: 100vw !important;
    width: 100vw !important;
    height: auto !important;
    padding: 55px 0 0 !important;
    flex: none;
  }
  #sidebarContent {
    max-width: inherit !important;
    width: inherit !important;
    overflow-y: scroll;
  }
  #sidebarContent button.close {
    left: 0 !important;
    position: relative !important;
  }
 .tabbedSideContent {
    max-width: 100vw !important;
    width: 100% !important;
    height: auto !important;
    padding: 55px 0 0 !important;
    flex: none;
  }
  .tabbedContent {
    max-width: 100vw !important;
    width: 100% !important;
  }
  .tabSection {
    display: block !important;
  }
  .sidebarNav ul, .tabbedSideContent ul {
    display: flex;
    flex-direction: row !important;
    align-items: flex-start;
    justify-content: space-between;
  }
  .sidebarNav li, .tabbedSideContent li {
    flex-basis: 1;
    margin: 0 !important;
  }
  .sidebarNav span, .tabbedSideContent span {
    flex: 1;
  }
  .content.sticky {
    padding-left: 0 !important;
    padding-top: 50px;
  }
  .tabbedSideContent .linkText {
    padding: 0px 5px !important;
    font-size: 1.2rem;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  #siteNav.header {
    display: none;
  }
  #siteNav.Hamburger {
    display: block;
  }
  #siteNav .mLink,
  #siteNav .sLink {
    height: 40px;
  }
  #siteNav.header.show .navUl {
    background: white;
    transition: all 0.2s ease 0s;
    border-radius: 6px 6px 6px 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 2px 2px 5px 0px;
    margin-top: 40px;
    width: 150px !important;
    float: right !important;
    height: auto !important;
    flex-wrap: wrap !important;
  }
  #siteNav.show {
    float: right;
  }
  #siteNav .submenuArrow {
    display: none !important;
  }
  #siteNav.header .navUl .navLi {
    width: 100%;
    height: 40px !important;
    position: relative;
    margin: 0 !important;
    background: none !important;
    text-align: left !important;
  }
  #siteNav.header .navUl .navLi.parent {
    padding-left: 10px !important;
    height: 35px !important;
  }
  #siteNav.header .navUl .navLi.child {
    padding-left: 20px !important;
    height: 30px !important;
  }
  #siteNav.header .navUl > .navLi.parent:not(:first-child) {
    border-top: 1px solid var(--color-data-border) !important;
  }
  #siteNav.header .navUl .navLi .highlight {
    left: 0;
  }
  #siteNav.header .navUl > li:not(#account) > .navSubmenu {
    display: none;
  }
  #siteNav.header .navUl > li > #accountLink span {
    display: block !important;
  }
  #siteNav.header .navUl #account.navLi .navSubmenu > li:first-child {
    display: none;
  }
  #siteNav.header .navUl #reportBar {
    display: none;
  }
  #siteNav #account .navSubmenu {
    display: block;
    opacity: 1 !important;
    max-height: unset !important;
    margin-top: 0 !important;
    text-align: center;
  }
  #siteLogo {
    height: 50px !important;
    width: 40px !important;
    float: left;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    transition: all .2s ease;
  }
  #siteHeader {
    height: calc(50px + var(--padding-header) + var(--padding-header)) !important;
  }
  .divider.top {
    height: 110px !important;
  }
  .summary {
    text-align: justify;
    padding: 20px;
    margin: 10px;
  }
  #main p,
  #careers p,
  #technology p,
  #vision p,
  #technology li,
  #privacy p {
    text-align: justify;
  }
  #vision p {
    padding: 80px 30px 30px 40px;
  }
  #whyChooseF1 li {
    min-width: inherit;
  }
  #nextSteps {
    padding-left: 0 !important;
  }
  #nextSteps .closingNote {
    flex: inherit;
  }
  #forgotPassword h1 {
    font-size: 2em;
    margin-bottom: 60px !important;
  }
  .userFormContainer {
    min-width: 300px !important;
  }
  .userFormInnerContainer {
    width: auto !important;
  }
  #captcha iframe {
    width: 100% !important;
  }
  #contact .contactContainer {
    max-width: 420px !important;
    padding: 20px !important;
    margin: 10px auto !important;
  }
  .paginationShowing {
    display: none;
  }
  .tabMenuItem.withIcon > div {
    padding: 0 !important;
  }
  .tabMenuItem.withIcon .mobileHide {
    display: none;
  }
  .tabMenuItem.withIcon .mobileIconOnly {
    height: 20px;
  }
  #newLRApplication {
    flex-direction: column !important;
  }
  #newLRApplication > div {
    margin-right: unset !important;
  }
  .pageHeader .children {
    display: flex;
    flex-direction: column;
  }
  .pageHeader > .children > div {
    padding-bottom: 0 !important;
  }
}

@media only screen and (max-width: 780px) {
  .pageHeader > .children .withLink {
    flex-direction: column;
  }
  .pageHeader > .children .withLink button {
    margin: 0 0 10px 0 !important;
  }
}

/* Site modal pagination on mobile */
@media only screen and (max-width: 475px) {
  #siteModal .modalContent, #modal .modalContent {
    height: calc(100vh - 150px) !important;
  }
  #siteModal .tableFooter, #modal .tableFooter {
    flex-wrap: wrap;
  }
  #siteModal .pagination, #modal .pagination {
    flex: 0 0 100%;
    max-width: initial !important;
  }
  #siteModal .dropMenu, #modal .dropMenu {
    flex: 0 0 50%;
    max-width: initial !important;
  }
  #siteModal .pagination .perPageWrapper, #modal .pagination .perPageWrapper {
    align-items: flex-start !important;
    flex-direction: column !important;
  }
}

/* Reports pagination on mobile */
@media only screen and (max-width: 440px) {
  #report #dataTableWrapper {
    padding-bottom: 85px !important; /* so pagination does not go behind footer */
  }
  #report .tableFooter {
    flex-wrap: wrap;
  }
  #report .perPageDisplay {
    margin-right: 0 !important;
  }
  #report #dataTableWrapper #label_per_page {
    display: block !important;
  }
  #report .pagination {
    flex: 0 0 100%;
    max-width: initial !important;
  }
  #report .dropMenu {
    flex: 0 0 50%;
    max-width: initial !important;
  }
  #report .tableFooter #label_per_page {
    display: block !important;
  }
}

.dashboard #loader {
  display: none !important;
}

@media (max-width: 442px) {
  #badgeRadioWrapper {
    max-width: 100%;
    margin-bottom: 10px;
  }
}
@media (min-width: 442px) {
  #badgeRadioWrapper {
    max-width: 300px;
  }
}

@media (max-width: 400px) {
  .dataBox {
    min-width: 296px !important;
  }
  .dataBox:not(.warning) {
    padding: 20px 5px !important;
  }
  .dataBox.warning .titleWrapper{
    padding: 20px 5px 10px !important;
  }
  .pageContent {
    padding: 0 !important;
  }
  #merchantInfo {
    padding: 5px 0 !important;
    min-width: 296px;
  }
  #salesContactButton {
    width: calc(100% - 4px);
  }
  .tabbedContent.content {
    padding: 0 5px !important;
  }
  .tabbedContent #feesTab {
    padding: 10px 0 !important;
  }
  .tabbedContent #report {
    padding: 10px 0 !important;
  }
  .tabbedContent #batchDetailsReportPage {
    padding: 10px 0 !important;
  }
  .tabbedContent #totals, #merchantLedgerPage #totals {
    padding: 10px 0 !important;
    min-width: 296px;
    width: 100% !important;
  }
}
@media (max-width: 363px) {
  .errors {
    font-size: .66em !important;
    margin-top: 10px;
  }
  #merchantGoButton {
    /* JumpToMerchant component layout breaks at mobile width without this */
    margin-bottom: 10px;
  }
  #filterBar .errors {
    margin-top: 0;
  }
  .errors div {
    line-height: 1em;
    text-align: left;
  }
  #add-application-button .desktopView {
    display: none;
  }
}
@media (max-width: 400px) {
  .tabContent form.horizontal {
    flex-direction: column;
  }
  .tabContent form.horizontal > a {
    margin: 10px 0 !important;
  }
  #jumpToMerchantComponent .combo-label-wrapper {
    width: calc(100% - 10px);
    margin-left: 5px;
    margin-top: 5px;
  }
  .merchant-toggle-wrapper {
    justify-content: center !important;
  }
}

@media (min-width: 776px) {
  .Hamburger {
    display: none;
  }
  #siteNav .navUl .subLink {
    display: none;
  }
}

@media (max-width: 532px) {
  .navUl #reportBar {
    display: none !important;
  }
  #sideNavUL {
    flex-direction: row !important;
  }
  #pageSidebarNav {
    flex: 0 0 100% !important;
    margin: 0 !important;
  }
  #pageTabContent {
    padding: 0 !important;
    margin-top: 0 !important;
  }
  #sideNavUL li {
    padding: 10px;
    text-align: center !important;
    margin-bottom: 0 !important;
  }
  #page.reportsPage {
    overflow: auto !important;
  }
  #page.reportsPage #dataTableWrapper {
    height: auto !important;
  }
  #page.reportsPage #dataTableWrapper {
    padding-top: 0 !important;
  }
}

@media (max-height: 800px) and (max-width: 1365px) {
  #main {
    flex-direction: column !important;
  }
  #page.reportsPage {
    max-height: none !important;
  }
  #page.reportsPage #dataTableWrapper {
    height: auto !important;
    max-height: none !important;
    padding-top: 0 !important;
    overflow: hidden !important;
  }
}

@media (min-width: 1366px) {
  #page.reportsPage #downloadAllWrapper {
    margin: 5px 5px 10px 5px !important;
    padding: 8px !important;
    font-size: 1.4rem !important;
  }
  #page.reportsPage .reset {
    padding-bottom: 0 !important;
    font-size: 1.2rem;
    line-height: 1.5;
  }
}

@media (min-width: 320px) {
  .signPostText {
    font-size: calc( 2.272727272727273vw + 2.7272727272727266px ) !important;
  }
  .focusOnOne {
    font-size: calc( 15vw + -8px ) !important;
    margin-bottom: 20px;
  }
  .raiseTheBar {
    font-size: calc( 8.695652173913043vw + 12.173913043478262px ) !important;
    margin-bottom: 10px;
  }
  .connectorStroke {
    stroke-width: calc( 0.25vw + 0.5px ) !important;
  }
  .divider.top .angle {
    height: calc( 5.88235294117647vw + 21.176470588235293px ) !important;
  }
}

@media (max-width: 600px) {
  .reportMiniBox .percent {
    line-height: 1;
    top: 0;
    right: 0;
  }
}

@media (min-width: 550px) {
  .focusOnOne {
    font-size: 70px !important;
  }
  .raiseTheBar {
    font-size: 60px !important;
  }
}

@media (max-width: 560px) {
  #delegateFilterBar .combo-label-wrapper {
    max-width: 100% !important;
  }
}

@media (max-width: 614px) {
  .imageGridDivider {
    display: none !important;
  }
  .imageGridImage {
    top: 0 !important;
    margin-top: 0 !important;
    transform: translate(0,0) !important;
  }
}

@media (min-width: 1000px) {
  .connectorStroke {
    stroke-width: 3 !important;
  }
  .divider.top .angle {
    height: 80px !important;
  }
}

@media (min-width: 1200px) {
  .signPostText {
    font-size: 30px !important;
  }
}

#newLRApplication > div {
  flex-grow: 1 !important;
}

#newLRApplication > div:last-child {
  flex-grow: 0 !important;
}

/* Hamburger CSS */
.Hamburger {
  position: absolute;
  width: 30px;
  height: 30px;
  overflow: visible;
  top: 28px;
  right: 10px;
  outline: none;
}

.Hamburger svg {
  position: absolute;
  top: -1px; /* fixes rendering issue in FF */
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  overflow: visible;
  cursor: pointer;
}

@media (min-width: 420px) {
  #siteModal.active, #modal.active {
    width: 100vw !important;
    height: 100vh !important;
    background: rgba(0, 0, 0, 0.8) !important;
    padding: 10px !important;
    z-index: 100 !important;
  }
  #siteModal.active .innerWrap, #modal.active .innerWrap {
    bottom: 5vh !important;
    height: auto !important;
    max-height: 95vh !important;
    min-height: 40vh !important;
    width: auto !important;
    max-width: 95vw !important;
    overflow-y: auto  !important;
    position: relative  !important;
    right: auto  !important;
    top: 50%  !important;
    left: 50%  !important;
    transform: translate(-50%, -50%)  !important;
    animation: modal-bounce-in 0.5s ease;
  }
  #siteModal.active.small .innerWrap, #modal.active.small .innerWrap {
    max-height: 40vh  !important;
    min-height: 10vh  !important;
    width: 80vw !important;
  }
  #siteModal.active.medium .innerWrap, #modal.active.medium .innerWrap {
    max-height: 80vh  !important;
    min-height: 20vh  !important;
    width: 90vw !important;
  }
  #siteModal.active.medium .modalContent, #modal.active.medium .modalContent {
    height: calc(100% - 100px) !important;
  }
  #modal.active.inSidebar {
    width: 100% !important;
    height: 100% !important;
    background: rgba(0, 0, 0, 0.5) !important;
    .innerWrap {
      max-height: unset !important;
      min-height: unset !important;
      width: 100% !important;
    }
  }
}

@media (min-width: 960px) {
  #siteModal.active .innerWrap, #modal.active:not(.inSidebar) .innerWrap {
    max-width: 900px !important;
  }
    
  #siteModal.active.medium .innerWrap, #modal.active.medium:not(.inSidebar) .innerWrap {
    max-width: 900px !important;
  }

  #siteModal.active.large .innerWrap, #modal.active.large:not(.inSidebar) .innerWrap {
    bottom: 5vh !important;
    height: 80vh  !important;
    max-width: 900px  !important;
    min-height: 0  !important;
    width: 90vw !important;
  }
  #siteModal.active.large .modalContent, 
  #siteModal.active.full .modalContent,
  #modal.active.large .modalContent, 
  #modal.active.full .modalContent {
    height: calc(100% - 100px) !important;
  }

  #siteModal.active.full .innerWrap, #modal.active.full .innerWrap {
    bottom: 5vh !important;
    height: 95vh   !important;
    max-width: 95vw  !important;
    min-height: 0  !important;
    width: 95vw !important;
  }
}

.siteModalSwalClose {
  background-color: var(--color-link);
  font-family: inherit;
}

.siteModalSwalClose:hover {
  background-color: var(--color-secondary) !important;
}


.swal-corvia-default .swal-button {
  background: var(--color-primary);
}
.swal-corvia-default .swal-button:hover {
  background: hsl(var(--color-secondary-hue), var(--color-secondary-saturation), calc(var(--color-secondary-lightness) + 12%));
}

.swal-corvia-default .swal-button--cancel {
  background: initial;
}
.swal-corvia-default .swal-button--cancel:hover {
  color: hsl(var(--color-secondary-hue), var(--color-secondary-saturation), calc(var(--color-secondary-lightness) + 12%));
  background: initial;
}

.swal-withdraw-success .swal-footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.swal-withdraw-success .swal-button--confirm {
  background-color: var(--color-secondary);
}

.swal-withdraw-success .swal-button--confirm:hover {
  background-color: var(--color-primary);
}

.swal-request-withdraw .swal-footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.swal-request-withdraw .swal-button-container:first-child {
  order: 10
}

.swal-request-withdraw .swal-button--confirm {
  background:none;
  border:none;
  color: var(--color-warning);
}

.swal-request-withdraw .swal-button--confirm:hover {
  background:none;
  border:none;
  color: var(--color-primary);
}

.swal-request-withdraw .swal-button--cancel {
  background: var(--color-secondary);
  color: var(--color-whiteish);
}

.swal-request-withdraw .swal-button--cancel:hover {
  background: var(--color-primary);
  color: var(--color-whiteish);
}
.swal-request-new-signature .swal-button--confirm {
  background: var(--color-secondary);
}
.swal-request-new-signature .swal-button--confirm:hover {
  background: var(--color-primary);
}

.swal-signature-success .swal-footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.swal-signature-success .swal-button--confirm {
  background-color: var(--color-secondary);
}

.swal-signature-success .swal-button--confirm:hover {
  background-color: var(--color-primary);
}

.swal-corvia-warning .swal-footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.swal-corvia-warning .swal-button-container:first-child {
  order: 10
}

.swal-corvia-warning .swal-button--confirm {
  background:none;
  border:none;
  color: var(--color-warning);
}

.swal-corvia-warning .swal-button--confirm:hover {
  background:none;
  border:none;
  color: var(--color-primary);
}

.swal-corvia-warning .swal-button--cancel {
  background: var(--color-secondary);
  color: var(--color-whiteish);
}

.swal-corvia-warning .swal-button--cancel:hover {
  background: var(--color-primary);
  color: var(--color-whiteish);
}

.swal-corvia-default .swal-button--confirm {
  background: var(--color-secondary);
}
.swal-corvia-default .swal-button--confirm:hover {
  background: var(--color-primary);
}

.swal-text {
  font-size: 1.6rem;
  padding: 0;
  line-height: 1.5;
  color: var(--color-text);
  opacity: 0.7;
}

.swal-title {
  word-break: break-all;
  color: var(--color-text);
}

.swal-modal {
  font-family: var(--font-family-body);
  background-color: var(--color-bg);
}

/* Action Suggested page */
@media (max-width: 690px) {
  #actionSuggestedChartWrapper {
    display: block !important;
  }

  #downloadAllHealth, #downloadAllNotifications {
    width: 60% !important
  }
}

.tabbedContent #sub-partner-onboarded,
.tabbedContent #sub-partner-volume,
.tabbedContent #merchant-health,
.tabbedContent #merchant-volume {
  padding: 0 !important;
  min-width: 276px;
}
#leaderboard .tabbedContent.content {
  padding: 1em 0.5em !important;
}

/* Google Map View */
@media (max-width: 345px) {
  #googleMapView, #googleStreetView {
    width: 200px;
  }
}

.sideNavWrap {
  display: flex;
  gap: 10px;
  flex-wrap: nowrap;
  flex-direction: row;
}
@media only screen and (max-width: 615px) {
  .sideNavWrap {
    flex-direction: column !important;
    flex-flow: column;
  }
}
